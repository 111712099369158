import { graphql, Script } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { HomeMobileFooter, HomeTabFooter } from "../assets/backgrounds";
import desktopFooter from "../assets/backgrounds/pricingFooter.png";
import { CloseFeature } from "../assets/icons/closeFeatures";
import { ShowFeature } from "../assets/icons/showFeatures";
import bg_video from "../assets/new-home-page/bg_graphic_flow.webm";
import graphicPoster from "../assets/new-home-page/bg_graphics_poster.jpg";
import CustomButton from "../components/CustomButton";
import CustomerStories from "../components/customerLp/CustomerStories";
import DemoModal from "../components/DemoModal";
import Layout from "../components/layout";
import LogoMarquee from "../components/LogoMarquee";
import CTASection from "../components/pricing_page/CTASection";
import FAQ from "../components/pricing_page/FAQ";
import HeroSection from "../components/pricing_page/HeroSection";
import PricingCard from "../components/pricing_page/PricingCard";
import TableBodyRowSection from "../components/pricing_page/TableBodyRowSection";
import TableHeaderSection from "../components/pricing_page/TableHeaderSection";
import VsTableChart from "../components/pricing_page/VsTableChart";
import SEO from "../components/seo";
import TestimonialMonet from "../components/Slices/TestimonialMonet";
import VideoComponent from "../components/VideoComponent";
import { ModalProvider } from "../Contexts/openModal";
import "../styles/page/new-pricing.scss";
import { appendTargetBlank, pickSlice } from "../utilities/helpers";

const getMaxWidth = {
  0: "max-w-[85px]",
  1: "max-w-[72px]",
  2: "max-w-[108px]",
};

const getTransform = {
  0: "translate-x-[0]",
  1: "translate-x-[120%]",
  2: "translate-x-[146%]",
};

const themeBackground = {
  gray: "text-gray-2500 bg-gray-2800 border border-gray-2200",
  blue: "bg-blue-1300 text-white border border-blue-1300",
  purple: "bg-purple-1500 text-white border border-purple-1500",
};

const PricingContent = ({ data, location }) => {
  const [openAnnotateFeature, setOpenAnnotateFeature] = useState(false);
  const [openActiveFeature, setOpenActiveFeature] = useState(false);
  const [openIndexFeature, setOpenIndexFeature] = useState(false);
  const [openSecurity, setOpenSecurity] = useState(true);
  const [activePlan, setActivePlan] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const testimonialRef = useRef(null);
  const tabRef = useRef(null);
  const securityRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 398 });
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    if (isSale) {
      setIsSale(false);
    }
    setIsOpen(true);
  };

  const handleTitle = () => {
    setIsSale(true);
    openModal();
  };

  const trusted_brands_list =
    data?.allPrismicTrustedBrandsLogos &&
    !!data.allPrismicTrustedBrandsLogos?.nodes.length &&
    data.allPrismicTrustedBrandsLogos?.nodes[0]?.data?.body[0];

  const _data = data?.prismicPricingV3?.data || {};

  const OpenAnnotateFeatures = () => {
    setOpenAnnotateFeature(!openAnnotateFeature);
  };
  const OpenActiveFeatures = () => {
    setOpenActiveFeature(!openActiveFeature);
  };
  const OpenIndexFeatures = () => {
    setOpenIndexFeature(!openIndexFeature);
  };

  const [faqs] = _data?.body?.filter((v) => v.slice_type === "table");
  const [banner] = _data?.body?.filter((v) => v.slice_type === "testimonial");
  const [case_study_cards] = pickSlice(_data, "case_study_cards");
  const [security_and_support] = pickSlice(_data, "security_and_support");
  const [platform_table] = pickSlice(_data, "platform");
  const [encord_active] = pickSlice(_data, "encord_active");
  const [encord_annotate] = pickSlice(_data, "encord_annotate");
  const [encord_index] = pickSlice(_data, "encord_index");
  const pricing_card = pickSlice(_data, "pricing_card");
  const [customerStories] = pickSlice(_data, "customer_stories_avatar");
  const [build_vs_buy_headingSection] = pickSlice(_data, "build_vs_buy_header");
  const build_vs_buy_cards = pickSlice(_data, "build_vs_buy");
  const testimonial_monet = pickSlice(_data, "testimonial_monet");
  const plans = pricing_card?.map((card, index) => {
    return {
      heading: card?.primary?.card_heading?.text,
      buttonText: card?.primary?.button_text?.text,
      onClick: index == 2 ? handleTitle : openModal,
      icon: card?.primary?.icon,
      themeColor: index === 0 ? "gray" : index === 1 ? "blue" : "purple",
    };
  });
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const newHeading = isMobile
    ? appendTargetBlank(_data?.heading?.html)?.replace(
        /(\S+\s+\S+)\s/,
        "$1<br/>"
      )
    : _data?.heading?.html;
  return (
    <section className="font-manrope bg-gray-2800 tracking-tighter_3">
      <div className="relative pt-32 md:pt-48">
        <VideoComponent
          autoPlay
          poster={graphicPoster}
          className="absolute left-0 right-0 z-0 [mix-blend-mode:darken] lg:top-20 sm:top-16"
        >
          <source
            className="row-start-1 row-end-4"
            src={bg_video}
            type="video/webm"
          />
        </VideoComponent>

        <HeroSection
          title={newHeading}
          description={_data?.description?.text}
        />
        {pricing_card?.length && (
          <article className="relative z-10 grid max-w-6xl grid-cols-1 px-5 mx-auto mb-16 lg:grid-cols-3 lg:flex-row md:flex-row lg:gap-x-6 gap-x-2 sm:gap-y-4 gap-y-5 lg:justify-center md:justify-start sm:mb-20 xl:px-0 lg:px-6 sm:px-10">
            {pricing_card?.map((card, index) => (
              <PricingCard
                icon={card?.primary?.icon}
                heading={card?.primary?.card_heading?.text}
                description={card?.primary?.card_description?.text}
                label={card?.primary?.list_label?.text}
                subLabel={card?.primary?.list_sub_label?.text}
                items={card?.items}
                buttonText={card?.primary?.button_text?.text}
                themeColor={
                  index == 0 ? "gray" : index == 1 ? "blue" : "purple"
                }
                logo1={card?.primary?.logo_1}
                logo2={card?.primary?.logo_2}
                logo3={card?.primary?.logo_3}
                onClick={index == 2 ? handleTitle : openModal}
              />
            ))}
          </article>
        )}
        <section className="mb-4 img_grayscale_opacity sm:mb-6 branding_loop_width">
          <p className="text-xs font-semibold text-center uppercase tracking-widest_1 text-gray-600 -mb-0.5 lg:mb-1">
            Trusted by pioneering AI teams
          </p>
          <LogoMarquee logoArray={trusted_brands_list} logoBackground="" />
        </section>
        <article className="max-w-6xl px-5 mx-auto xl:px-0 lg:px-6 sm:px-10">
          {/* <Testimonial
            logo={banner?.items[0]?.avatar}
            message={banner?.items[0]?.comment.text}
            owner={banner?.items[0]?.name.text}
            position={banner?.items[0]?.role.text}
          /> */}
          <TestimonialMonet slice={testimonial_monet[0]} />
          <section>
            <section className="min-h-[120px] sm:min-h-[auto] mb-8">
              <div className="text-purple-1100 sm:text-4.5xl text-2.5xl text-center font-bold sm:mt-10 mt-8 sm:mb-16 mb-8">
                {_data?.pricing_table_heading?.text}
              </div>
              <div className="flex justify-center" ref={tabRef}>
                <div
                  className={`sm:hidden ease-in-out flex bg-gray-1500 rounded-full tracking-tight_1 font-semibold text-gray-2500 p-1.5 justify-between shadow-section gap-y-2.5 ${
                    scrollY <= testimonialRef?.current?.offsetTop - 210 &&
                    scrollY >= tabRef?.current?.offsetTop - 80
                      ? "fixed z-10 top-20 transition-all ease-out"
                      : "relative transition-all ease-in"
                  }`}
                >
                  {plans.map((plan, index) => (
                    <div
                      className="z-10 px-4 py-2 rounded-full"
                      onClick={() => setActivePlan(index)}
                    >
                      {plan?.heading}
                    </div>
                  ))}
                  <span
                    className={`bg-white ${getMaxWidth[activePlan]} m-1.5 shadow-card py-2 sm:px-6 inset-0 rounded-full delay-100 duration-200 z-0 ease-in px-4 absolute ${getTransform[activePlan]}`}
                  ></span>
                </div>
              </div>
            </section>
            <section className="w-full mx-auto rounded-2.5xl bg-white border border-gray-1500 pb-8 sm:pb-12 sm:mb-11 mb-10">
              {plans && (
                <div
                  className={`justify-end hidden my-8 sm:flex gap-5 lg:gap-0 w-full ease-in-out py-8 xl:max-w-6xl min-[1158px]:max-w-[1102px] lg:max-w-[95%] min-[860px]:max-w-[91%] md:max-w-[90%] min-[720px]:max-w-[89%] max-w-[88%] right-1/2 translate-x-1/2 mx-auto ${
                    scrollY <= securityRef?.current?.offsetTop &&
                    scrollY >= tabRef?.current?.offsetTop - 12
                      ? "fixed z-10 top-8  lg:px-0 px-5 bg-white transition-all ease-out plan-shadow"
                      : "hidden sm:hidden transition-all ease-in"
                  }  `}
                >
                  {plans?.map((plan, index) => (
                    <div className="flex flex-col text-center items-center lg:w-full xl:max-w-[234px] lg:max-w-[200px]">
                      <img
                        src={plan?.icon?.url}
                        w={0}
                        h={0}
                        alt={plan?.icon?.alt || "plan icon"}
                        className="w-7.5 h-auto mb-1.5"
                      />
                      <div
                        className={`mb-5 text-xl font-semibold
              ${
                index === 0
                  ? "text-gray-600"
                  : index === 1
                    ? "text-blue-1300"
                    : "text-purple-1500"
              } font-inter`}
                      >
                        {plan.heading}
                      </div>
                      <div className="flex justify-center">
                        <CustomButton
                          text={plan?.buttonText}
                          onClick={plan?.onClick}
                          buttonClass={`${
                            themeBackground[plan?.themeColor]
                          } text-sm  ${
                            index === 0
                              ? "sm:hover:bg-gray-1600"
                              : index === 1
                                ? "sm:hover:bg-blue-1500"
                                : "sm:hover:bg-purple-1800"
                          }`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* Platform Section */}
              <section className=" rounded-tr-2.5xl rounded-tl-2.5xl bg-white border border-gray-1500 pb-8 mb-10 sm:mb-11">
                <div className="mb-4 md:mb-0">
                  <TableHeaderSection
                    title={platform_table?.primary?.section_heading?.text}
                    tooltipText={platform_table?.primary?.section_tooltip?.text}
                    icon={platform_table?.primary?.icon}
                    iconStyle="!w-auto"
                    plans={plans}
                  />
                </div>
                <TableBodyRowSection
                  openFeature={openSecurity}
                  tableBodyRowData={platform_table}
                  activePlan={activePlan}
                  tableTheme="black"
                />
              </section>

              <TableHeaderSection
                title={encord_annotate?.primary?.section_heading?.text}
                tooltipText={encord_annotate?.primary?.section_tooltip?.text}
                icon={encord_annotate?.primary?.icon}
              />
              <TableBodyRowSection
                openFeature={openAnnotateFeature}
                tableBodyRowData={encord_annotate}
                activePlan={activePlan}
                tableTheme="purple"
                tabletLength={1.85}
                mobileLength={1.9}
                desktopLength={1.85}
                smallDesktoplength={1.6}
              />
            </section>
          </section>

          {openAnnotateFeature ? (
            <CustomButton
              text="Show less"
              icon={<CloseFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="top"
              onClick={OpenAnnotateFeatures}
            />
          ) : (
            <CustomButton
              text="Show more features"
              icon={<ShowFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="bottom"
              onClick={OpenAnnotateFeatures}
            />
          )}
        </article>
        {/* Index */}
        <article className="max-w-6xl px-5 mx-auto xl:px-0 lg:px-6 sm:px-10">
          <section className="w-full mx-auto rounded-2.5xl bg-white border border-gray-1500 pb-8 sm:pb-12 sm:my-11 my-10">
            <TableHeaderSection
              title={encord_index?.primary?.section_heading?.text}
              tooltipText={encord_index?.primary?.section_tooltip?.text}
              icon={encord_index?.primary?.icon}
            />
            <TableBodyRowSection
              openFeature={openIndexFeature}
              tableBodyRowData={encord_index}
              activePlan={activePlan}
              tableTheme="black"
              tabletLength={2.6}
              mobileLength={3}
              desktopLength={2.7}
              smallDesktoplength={2.23}
            />
          </section>

          {openIndexFeature ? (
            <CustomButton
              text="Show less"
              icon={<CloseFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="top"
              onClick={OpenIndexFeatures}
            />
          ) : (
            <CustomButton
              text="Show more features"
              icon={<ShowFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="bottom"
              onClick={OpenIndexFeatures}
            />
          )}
        </article>

        {/* acitve */}
        <article className="max-w-6xl px-5 mx-auto xl:px-0 lg:px-6 sm:px-10">
          <section className="w-full mx-auto rounded-2.5xl bg-white border border-gray-1500 pb-8 sm:pb-12 sm:my-11 my-10">
            <TableHeaderSection
              title={encord_active?.primary?.section_heading?.text}
              tooltipText={encord_active?.primary?.section_tooltip?.text}
              icon={encord_active?.primary?.icon}
            />
            <TableBodyRowSection
              openFeature={openActiveFeature}
              tableBodyRowData={encord_active}
              activePlan={activePlan}
              tableTheme="green"
              tabletLength={2.73}
              mobileLength={3}
              desktopLength={2.7}
              smallDesktoplength={2.23}
            />
          </section>
          {openActiveFeature ? (
            <CustomButton
              text="Show less"
              icon={<CloseFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="top"
              onClick={OpenActiveFeatures}
            />
          ) : (
            <CustomButton
              text="Show more features"
              icon={<ShowFeature />}
              buttonClass="text-gray-2500 hover:text-gray-2700 hover:bg-gray-1600 sm:text-lg mx-auto border border-gray-2200"
              direction="bottom"
              onClick={OpenActiveFeatures}
            />
          )}
        </article>

        {/* security */}
        <article
          className="max-w-6xl px-5 mx-auto xl:px-0 lg:px-6 sm:px-10"
          ref={securityRef}
        >
          <section className="w-full mx-auto rounded-2.5xl bg-white border border-gray-1500 pb-8 sm:pb-10 sm:mt-11 mt-10">
            <div className="mb-4 md:mb-0">
              <TableHeaderSection
                title={security_and_support?.primary?.section_heading?.text}
                tooltipText={
                  security_and_support?.primary?.section_tooltip?.text
                }
                icon={security_and_support?.primary?.icon}
              />
            </div>
            <TableBodyRowSection
              openFeature={openSecurity}
              tableBodyRowData={security_and_support}
              activePlan={activePlan}
              tableTheme="black"
            />
          </section>
        </article>
        <div ref={testimonialRef} />
        {/* Build vs Buy Table */}
        <article className="px-4 pt-10 pb-10 sm:pt-20 sm:pb-10 sm:px-12 md:px-auto ">
          <VsTableChart
            headingData={build_vs_buy_headingSection?.primary}
            cardsData={build_vs_buy_cards}
            openModal={openModal}
          />
        </article>
        <section className="max-w-6xl px-5 py-8 mx-auto sm:py-10 lg:my-10 xl:px-0 lg:px-6 sm:px-10">
          <CustomerStories data={customerStories} />
        </section>
        {/* <article className="max-w-6xl px-5 py-8 mx-auto overflow-x-auto sm:py-10 xl:px-0 lg:px-6 sm:px-10">
          <CaseStudyCards data={case_study_cards} />
        </article> */}
        <div className="max-w-6xl px-4 pt-8 mx-auto sm:pt-10 xl:px-0 lg:px-6 sm:px-10">
          <FAQ
            heading={faqs?.primary?.section_heading?.text}
            items={faqs.items}
          />
        </div>

        <article className="relative mt-16 overflow-hidden lg:mt-32 sm:mt-20 bg-purple-1100">
          <div className="absolute left-0 z-0 hidden -bottom-11 lg:block">
            <img
              src={desktopFooter}
              alt="anotate vector"
              loading="eager"
              className="w-full h-auto opacity-60"
            />
          </div>
          <div className="absolute hidden -bottom-2 sm:block lg:hidden">
            <HomeTabFooter />
          </div>
          <div className="absolute bottom-2 sm:hidden">
            <HomeMobileFooter />
          </div>
          <article className="relative mx-5 py-36 max-w-7xl xl:px-16 xl:mx-auto sm:mx-6">
            <CTASection
              heading={_data?.cta_heading?.text}
              description={_data?.cta_description?.text}
              location={location}
              openModal={openModal}
            />
          </article>
        </article>
      </div>
      <DemoModal
        hideSuccessModal={location?.pathname?.includes("/pricing")}
        isOpen={isOpen}
        closeModal={closeModal}
        location={location}
        title={isSale ? "Contact sales" : "Get Started"}
      />
    </section>
  );
};

const Pricing = (props) => {
  return (
    <Layout location={props?.location} theme="default">
      <ModalProvider>
        <PricingContent {...props} />
      </ModalProvider>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicTrustedBrandsLogos(
      filter: { uid: { eq: "pricing-page-logos" } }
    ) {
      nodes {
        data {
          body {
            ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
              id
              primary {
                label {
                  text
                }
              }
              items {
                brand_image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPricingV3 {
      data {
        heading {
          text
          html
        }
        description {
          text
          html
        }
        pricing_table_heading {
          text
          html
        }
        cta_heading {
          html
          text
        }
        cta_description {
          text
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        body {
          ... on PrismicPricingV3DataBodyTable {
            id
            items {
              answer {
                text
                html
              }
              question {
                html
                text
              }
            }
            primary {
              section_heading {
                text
              }
            }
            slice_type
          }
          ... on PrismicPricingV3DataBodyEncordAnnotate {
            id
            slice_type
            primary {
              section_tooltip {
                text
              }
              section_heading {
                text
              }
              icon {
                url
                alt
              }
            }
            items {
              team_column_text {
                text
              }
              label_tooltip {
                text
              }
              label {
                text
              }
              free_column_text {
                text
              }
              feature_name_tooltip {
                text
              }
              feature_name {
                text
              }
              enterprise_column_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyEncordActive {
            id
            slice_type
            primary {
              section_tooltip {
                text
              }
              section_heading {
                text
              }
              icon {
                url
                alt
              }
            }
            items {
              team_column_text {
                text
              }
              label_tooltip {
                text
              }
              label {
                text
              }
              free_column_text {
                text
              }
              feature_name_tooltip {
                text
              }
              feature_name {
                text
              }
              enterprise_column_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyEncordIndex {
            id
            slice_type
            primary {
              section_tooltip {
                text
              }
              section_heading {
                text
              }
              icon {
                url
                alt
              }
            }
            items {
              team_column_text {
                text
              }
              label_tooltip {
                text
              }
              label {
                text
              }
              free_column_text {
                text
              }
              feature_name_tooltip {
                text
              }
              feature_name {
                text
              }
              enterprise_column_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyPlatform {
            id
            slice_type
            primary {
              section_tooltip {
                text
              }
              section_heading {
                text
              }
              icon {
                alt
                url
              }
            }
            items {
              team_column_text {
                text
              }
              label_tooltip {
                text
              }
              label {
                text
              }
              free_column_text {
                text
              }
              feature_name_tooltip {
                text
              }
              feature_name {
                text
              }
              enterprise_column_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodySecurityAndSupport {
            id
            slice_type
            primary {
              section_tooltip {
                text
              }
              section_heading {
                text
              }
              icon {
                alt
                url
              }
            }
            items {
              team_column_text {
                text
              }
              label_tooltip {
                text
              }
              label {
                text
              }
              free_column_text {
                text
              }
              feature_name_tooltip {
                text
              }
              feature_name {
                text
              }
              enterprise_column_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyPricingCard {
            id
            slice_type
            primary {
              list_sub_label {
                text
              }
              list_label {
                text
              }
              icon {
                url
                alt
              }
              logo_1 {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              logo_2 {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              logo_3 {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              card_heading {
                text
              }
              card_description {
                text
              }
              button_text {
                text
              }
              button_link {
                url
              }
            }
            items {
              list_item {
                text
              }
              add_on_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyCaseStudyCards {
            id
            slice_type
            items {
              link {
                url
              }
              icon {
                url
                alt
              }
              description1 {
                text
              }
              button_text {
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyTestimonial {
            id
            slice_type
            items {
              role {
                text
              }
              name {
                text
              }
              comment {
                text
              }
              avatar {
                alt
                url
              }
            }
          }
          ... on PrismicPricingV3DataBodyCustomerStoriesAvatar {
            id
            items {
              avatar {
                url
                alt
              }
              company_logo {
                alt
                url
              }
              company_name {
                text
              }
              description1 {
                text
              }
              designation {
                text
              }
              heading1 {
                html
                text
              }
              name {
                text
              }
              cs_link {
                url
              }
            }
            primary {
              section_heading {
                html
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPricingV3DataBodyBuildVsBuy {
            id
            primary {
              label {
                text
              }
            }
            items {
              item_text {
                text
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPricingV3DataBodyBuildVsBuyHeader {
            id
            slice_label
            slice_type
            primary {
              section_description {
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicPricingV3DataBodyTestimonialMonet {
            id
            slice_type
            items {
              author_image {
                url
              }
              author_name {
                text
              }
              author_job_designation {
                text
              }
              cta_icon {
                url
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              testimonial_text {
                text
              }
            }
            primary {
              caption_title_style
              heading_section_position
              show_caption_title
              theme
              title {
                text
              }
              caption_title {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default Pricing;

export const Head = ({ data }) => {
  const _data = data?.prismicPricingV3?.data || {};

  return (
    <>
      <Script id="g2crowd-tracking-script" defer>
        {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
      </Script>
      <SEO
        title={_data?.meta_heading?.text}
        description={_data?.meta_description?.text}
      />
    </>
  );
};
